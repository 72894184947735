import {Button, Flex, Input, Popconfirm, Select, Space, message, Tooltip} from 'antd';
import React, {useEffect} from 'react';
import {
  SearchOutlined,
  ExportOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  SyncOutlined,
  CheckOutlined,
  IssuesCloseOutlined,
  CheckSquareOutlined,
  Loading3QuartersOutlined, QuestionCircleOutlined, LoadingOutlined
} from '@ant-design/icons';
import {handleExport} from '../../common/ExportExcel';
import {useState} from 'react';
import {
  createApproveSuggest,
  getLink,
  postConfigCheckIndex,
  postConfigIndex,
  postConfigLink, requestApproveSuggest, updateProcessingIndexed,
} from '../../../services/api';
import ImportExcel from '../../common/ImportExcel';
import {useDispatch, useSelector} from 'react-redux';
import {filter, setFilterValue} from '../../../redux/reducer';
import ModalExportExcel from "./ModalExportExcel";
import {APPROVE_STATUS, APPROVE_STATUS_ENUM} from "../../common/Enum";

const headerRowFile = [
  'STT',
  'URL',
  'Người tạo',
  'Ngày tạo',
  'Ngày check',
  'Is follow',
  'Is index',
  'Indexed',
  'Trạng thái',
];

const headerRowFileCheckLink = [
  'STT',
  'URL',
  'Từ khóa',
  'Ngày check',
  'Trạng thái check link',
  'Trạng thái index',
];

export const ManageLink = (props) => {
  const {
    getValueSearch,
    oppenModalLink,
    search,
    handleDeleteSelectRows,
    selectedRowKeys,
    dataLink,
    detailSuggest,
    suggestId,
    socket,
    handleCheckLinkSocket,
    fetchDataLink,
    loading,
    showModal,
    onSubmitRequestApproveSuccess
  } = props;
  const [open, setOpen] = useState(false);
  const [openCheckIndex, setOpenCheckIndex] = useState(false);
  const [openIndex, setOpenIndex] = useState(false);
  const [openRequestApprove, setOpenRequestApprove] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [isExportCheckLink, setIsExportCheckLink] = useState(false);
  const {isFollow, isIndex, indexed, status, idLink} = useSelector((state) => state.filterLinkSlice)

  const dispatch = useDispatch();

  const showPopconfirmApprove = () => {
    setOpenRequestApprove(true);
    setOpen(false);
    setOpenCheckIndex(false);
    setOpenIndex(false);
  }

  const showPopconfirm = () => {
    setOpen(true);
    setOpenCheckIndex(false);
    setOpenIndex(false);
  };

  const showPopCheckIndex = () => {
    setOpen(false);
    setOpenCheckIndex(true);
    setOpenIndex(false);
  };

  const showPopIndex = () => {
    setOpen(false);
    setOpenCheckIndex(false);
    setOpenIndex(true);
  };

  const handleCancel = () => {
    if (open === true) setOpen(false);
    if (openCheckIndex === true) setOpenCheckIndex(false);
    if (openIndex === true) setOpenIndex(false);
  };

  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      const res = await postConfigLink({suggestId: suggestId});
      if (res.status === true) {
        message.success('Check link thành công!');
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error('Có lỗi xảy ra!');
    } finally {
      setOpen(false);
      setConfirmLoading(false);
    }
  };

  const handleCheckIndex = async () => {
    try {
      setConfirmLoading(true);
      const res = await postConfigCheckIndex({suggestId: suggestId});
      if (res.status === true) {
        message.success('Check index thành công!');
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error('Có lỗi xảy ra!');
    } finally {
      setOpenCheckIndex(false);
      setConfirmLoading(false);

      fetchDataLink();
    }
  };

  const handleIndex = async () => {
    try {
      setConfirmLoading(true);
      const res = await postConfigIndex({suggestId: suggestId});
      if (res.status === false) {
        message.error(res.message);
      } else {
        message.success('Đã gửi yêu cầu index!');
      }
    } catch (e) {
      message.error('Có lỗi xảy ra!');
    } finally {
      setOpenIndex(false);
      setConfirmLoading(false);
    }
  };

  const handleChange = (value, name) => {
    dispatch(setFilterValue({name, value}));
  };

  const handleExportExcel = (suggestId) => {
    setIsExport(true);
    getLink({suggestId: suggestId, limit: 99999, page: 1})
      .then(async (res) => {
        if (res?.data?.items?.length) {
          await handleExport(
            res.data.items,
            headerRowFile,
            `linkList-${detailSuggest?.websiteId?.domain}`
          );
        }
      })
      .catch((err) => {
        message.error('Có lỗi xảy ra!');
      })
      .finally(() => {
        setIsExport(false);
      });
  }

  const handleExportExcelCheckLink = (suggestId) => {
    setIsExportCheckLink(true);
    getLink({suggestId: suggestId, limit: 99999, page: 1})
      .then(async (res) => {
        if (res?.data?.items?.length) {
          await handleExport(
            res.data.items,
            headerRowFileCheckLink,
            `linkList-${detailSuggest?.websiteId?.domain}`
          );
        }
      })
      .catch((err) => {
        message.error('Có lỗi xảy ra!');
      })
      .finally(() => {
        setIsExportCheckLink(false);
      });
  }

  const [openModalExportExcel, setOpenModalExportExcel] = useState(false);
  /*const handleExportExcel = (suggestId) => {
    setOpenModalExportExcel(true);
  }*/
  const handleCloseModalExportExcel = () => {
    setOpenModalExportExcel(false);
  }

  const submitModalExportExcel = ({type, checkedList}) => {
    if (type === 'all') {
      handleExportExcelCheckLink(suggestId);
    }
  }

  const [isRequestApprove, setIsRequestApprove] = useState(false);
  const requestApprove = async () => {
    setIsRequestApprove(true);
    try {
      const suggestId = detailSuggest?._id;
      const res = await createApproveSuggest({
        suggestId: suggestId,
        userId: detailSuggest?.userId?._id
      });
      if (!res?.status) {
        message.error(res?.message || 'Có lỗi xảy ra');
      } else {
        message.success('Yêu cầu duyệt thành công');
        onSubmitRequestApproveSuccess();
      }
    } catch (e) {
      message.error('Có lỗi xảy ra');
    } finally {
      setIsRequestApprove(false);
      setOpenRequestApprove(false);
    }
  }

  const checkIndexLink = async () => {
    try {
      setConfirmLoading(true);
      const resData = await getLink({suggestId: suggestId, limit: 99999, page: 1})
      const links = resData.data.items;
      const linkData = links.map((item) => {
        return {
          link: `site:${item.linkUrl}`,
          data_id: item._id
        }
      });
      window.postMessage({
        type: "OKCHECK_WRITE_INDEX",
        message: linkData,
      });


      const res = await updateProcessingIndexed(suggestId);
      if (res.status) {
        message.success('Check index thành công!');

        setTimeout(() => {
          window.postMessage({
            type: "OKCHECK_CHECK_INDEX",
          });
        }, 1000)
      } else {
        message.error(res.message);
      }
    } catch (e) {
      message.error('Có lỗi xảy ra!');
    } finally {
      setOpenCheckIndex(false);
      setConfirmLoading(false);

      fetchDataLink();
    }
  }

  return (
    <>
      <p className='text-2xl font-semibold py-4'>Danh sách Link</p>
      <Flex gap={'small'} className='mb-3' wrap='wrap'>
        <Space.Compact style={{width: 300}}>
          <Input
            addonBefore={<SearchOutlined/>}
            placeholder='Tìm kiếm URL...'
            onChange={(e) => getValueSearch(e.target.value)}
            allowClear
            value={search}
          />
        </Space.Compact>

        <Select
          placeholder='Lọc isFollow'
          style={{
            width: 120,
          }}
          allowClear
          onChange={(value) => handleChange(value, 'isFollow')}
          options={[
            {
              value: 'follow',
              label: 'Follow',
            },
            {
              value: 'waiting',
              label: 'Waiting',
            },
            {
              value: 'nofollow',
              label: 'Nofollow',
            },
          ]}
        />
        <Select
          allowClear
          placeholder='Lọc isIndex'
          style={{
            width: 120,
          }}
          onChange={(value) => handleChange(value, 'isIndex')}
          options={[
            {
              value: 'index',
              label: 'Index',
            },
            {
              value: 'waiting',
              label: 'Waiting',
            },
            {
              value: 'noindex',
              label: 'Noindex',
            },
            {
              value: 'fail',
              label: 'Fail',
            },
          ]}
        />
        <Select
          allowClear
          placeholder='Trạng thái check link'
          style={{
            width: 120,
          }}
          onChange={(value) => handleChange(value, 'status')}
          options={[
            {
              value: 'success',
              label: 'Link còn',
            },
            {
              value: 'cancel',
              label: 'Link mất',
            },
            {
              value: 'waiting',
              label: 'Đang xử lý',
            },
          ]}
        />
        <Select
          allowClear
          placeholder='Lọc chỉ mục'
          style={{
            width: 120,
          }}
          onChange={(value) => handleChange(value, 'indexed')}
          options={[
            {
              value: 'index',
              label: 'Index',
            },
            {
              value: 'noindex',
              label: 'No index',
            },
            {
              value: 'waiting',
              label: 'Chưa xử lý',
            },
            {
              value: 'fail',
              label: 'Thất bại',
            },
            {
              value: 'processing',
              label: 'Đã gửi yêu cầu',
            },
          ]}
        />
      </Flex>
      <div className='flex mb-3 gap-2'>
        <Button
          type='primary'
          className='add-btn2'
          onClick={() => oppenModalLink()}
          icon={<PlusOutlined/>}
        >
          Thêm Link mới
        </Button>

        <ImportExcel suggestId={suggestId}/>

        <Button
          onClick={() => handleExportExcel(suggestId)}
          className='text-[#16a34a] border-[#16a34a] hover:bg-[#16a34a] hover:text-white'
          disabled={dataLink?.length === 0 || isExport}
          icon={isExport ? <Loading3QuartersOutlined spin/> : <ExportOutlined/>}
        >
          Xuất Excel
        </Button>

        <Button
          onClick={() => handleExportExcelCheckLink(suggestId)}
          className='text-[#16a34a] border-[#16a34a] hover:bg-[#16a34a] hover:text-white'
          disabled={dataLink?.length === 0 || isExportCheckLink}
          icon={isExportCheckLink ? <Loading3QuartersOutlined spin/> : <ExportOutlined/>}
        >
          Xuất Data Check Link
        </Button>

        <Button
          type='primary'
          onClick={showModal}
          className='btn-modal'
          icon={<CheckCircleOutlined/>}
        >
          Kiểm tra
        </Button>
        <Button
          onClick={handleDeleteSelectRows}
          type='primary'
          danger
          disabled={selectedRowKeys.length === 0}
          icon={<DeleteOutlined/>}
        >
          Xóa Tất Cả
        </Button>

        <Button
          onClick={fetchDataLink}
          type='primary'
          className='add-reset flex items-center bg-slate-600 text-white'
          style={{margin: 0}}
          disabled={loading}
          icon={<SyncOutlined spin={loading}/>}
        >
          Reload
        </Button>
      </div>
      <Space className='mb-3'>
        <Popconfirm
          title='Vui lòng xác nhận!'
          description={
            <>
              Bạn muốn check các URL của {detailSuggest?.websiteId?.domain} ?
              <br/>
              Check Link giúp kiểm tra tình trạng các link có
              <br/> website của mình không
            </>
          }
          okText='Có '
          cancelText='Không'
          open={open}
          onConfirm={handleOk}
          okButtonProps={{
            loading: confirmLoading,
            className: 'bg-blue-500',
          }}
          onCancel={handleCancel}
          cancelButtonProps={{className: 'cancelButtonProps'}}
        >
          <Button
            type='primary'
            danger
            className='bg-blue-500'
            disabled={dataLink?.length === 0}
            onClick={showPopconfirm}
            icon={<CheckOutlined/>}
          >
            Check Link
          </Button>
        </Popconfirm>
        <Popconfirm
          title='Vui lòng xác nhận!'
          description={
            <>
              Check Index giúp kiểm tra các Link đã Index trên google
              <br/> hay chưa.
            </>
          }
          okText='Có '
          cancelText='Không'
          open={openCheckIndex}
          onConfirm={checkIndexLink}
          okButtonProps={{
            loading: confirmLoading,
            className: 'bg-blue-500',
          }}
          onCancel={handleCancel}
          cancelButtonProps={{className: 'cancelButtonProps'}}
        >
          <Button
            onClick={showPopCheckIndex}
            type='primary'
            danger
            disabled={dataLink?.length === 0}
            icon={<IssuesCloseOutlined/>}
          >
            Check Index
          </Button>
        </Popconfirm>
        { detailSuggest?.approve?.status !== APPROVE_STATUS_ENUM.WAITING && (
          <>
            <Popconfirm
              title='Vui lòng xác nhận!'
              description={
                <>
                  Gửi yêu cầu duyệt đề xuất cho leader
                  <br/>
                  Sau khi leader duyệt hệ thống sẽ tự động index
                </>
              }
              okText='Có '
              cancelText='Không'
              open={openRequestApprove}
              onConfirm={requestApprove}
              okButtonProps={{loading: isRequestApprove, className: 'bg-blue-500',}}
              onCancel={() => setOpenRequestApprove(false)}
              cancelButtonProps={{className: 'cancelButtonProps'}}
            >
              <Button
                type='primary'
                danger
                disabled={isRequestApprove}
                onClick={showPopconfirmApprove}
                icon={isRequestApprove ? <LoadingOutlined spin={loading}/> : <QuestionCircleOutlined/>}
              >
                Đề xuất duyệt Index
              </Button>
            </Popconfirm>
          </>
        )}

        { detailSuggest?.approve?.status === APPROVE_STATUS_ENUM.WAITING && (
          <Button
            style={{
              backgroundColor: '#f1c991',
              color: '#fff',
              border: 'none',
            }}
            disabled={true}
            icon={<QuestionCircleOutlined/>}
          >
            Chờ TT/TP duyệt
          </Button>
        )}

        {/*<>
          <Popconfirm
            title='Vui lòng xác nhận!'
            description={
              <>
                Sau khi gửi yêu cầu index, leader sẽ xác nhận.
                <br /> Hệ thống sẽ tự động index các link đã được duyệt.
              </>
            }
            okText='Có '
            cancelText='Không'
            open={openIndex}
            onConfirm={handleIndex}
            okButtonProps={{
              loading: confirmLoading,
              className: 'bg-blue-500',
            }}
            onCancel={handleCancel}
            cancelButtonProps={{ className: 'cancelButtonProps' }}
          >
            <Button
              onClick={showPopIndex}
              type='primary'
              danger
              disabled={dataLink?.length === 0}
              icon={<CheckSquareOutlined />}
            >
              Index
            </Button>
          </Popconfirm>
        </>*/}
      </Space>

      <ModalExportExcel
        show={openModalExportExcel}
        handleCloseModalExportExcel={handleCloseModalExportExcel}
        submitModalExportExcel={submitModalExportExcel}
      />
    </>
  );
};
